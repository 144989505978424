import React from "react";
import UploadWidget from "./upload_widget/UploadWidget";

const HomePage: React.FC<{ path?: string }> = (props) => {
  return <div>
    <h1 className="text-4xl py-4 text-center">PDFwithMe</h1>
    <p className="py-3 text-center">Instantly collaborate on PDF documents</p>
    <UploadWidget className="mx-auto" />
    </div>;
};

export default HomePage;
