import React, { createRef } from "react";
import "pdfjs-dist/web/pdf_viewer.css";
import * as pdfjs from "pdfjs-dist";
import Page from "./page";
import { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist/types/display/api";
import { EventBus } from "pdfjs-dist/web/pdf_viewer";
import ViewerContext from "./context";
import { clearSelection, publishSelection, socket } from "../sync_manager";
import type { AnnotationStorage } from "./annotation_storage";
import { connect } from "react-redux";
import { selectAnnotationStorage, selectScale } from "./slice";
import { RootState } from "../app/store";

/* eslint import/no-webpack-loader-syntax: off */
pdfjs.GlobalWorkerOptions.workerSrc =
  require("!!file-loader!pdfjs-dist/build/pdf.worker").default;

interface Props {
  pdf: PDFDocumentProxy;
  annotationStorage?: AnnotationStorage;
  scale?: number;
}

interface State {
  pages?: Promise<PDFPageProxy>[];
}

function intersect(rect1: DOMRectReadOnly, rect2: DOMRectReadOnly) {
  return !(
    rect1.right <= rect2.left ||
    rect1.left >= rect2.right ||
    rect1.bottom <= rect2.top ||
    rect1.top >= rect2.bottom
  );
}

class _PdfDocument extends React.PureComponent<Props, State> {
  state: State = {};

  treeContext = {
    eventBus: new EventBus(),
  };

  pdf: PDFDocumentProxy | undefined = undefined;

  containerRef = createRef<HTMLDivElement>();

  private onSelectionChange = () => {
    const scale = this.props.scale || 1;
    const selection = window.getSelection();
    if (selection && selection.type === "Range") {
      if (this.containerRef.current) {
        const matches = [];

        const pageEls = this.containerRef.current.children;
        let page = 0;
        let pageRect = pageEls[0].getBoundingClientRect();

        const selectionRects = [];
        for (let i = 0; i < selection.rangeCount; i++) {
          const rects = selection.getRangeAt(i).getClientRects();
          for (let j = 0; j < rects.length; j++) {
            const rect = rects[j];
            if (rect.width > 0 && rect.height > 0) {
              selectionRects.push(rect);
            }
          }
        }

        for (let i = 0; i < selectionRects.length; ) {
          const rect = selectionRects[i];
          if (intersect(pageRect, rect)) {
            i++;

            matches.push({
              page,
              x: (rect.left - pageRect.left) / scale,
              y: (pageRect.bottom - rect.bottom) / scale,
              width: Math.abs(rect.width) / scale,
              height: Math.abs(rect.height) / scale,
            });
          } else {
            page++;
            if (page < pageEls.length) {
              pageRect = pageEls[page].getBoundingClientRect();
            } else {
              break;
            }
          }
        }

        if (matches.length > 0) {
          publishSelection(matches);
        }
      }
    } else {
      clearSelection();
    }
  };

  private updateAnnotation = (origin: string, key: string, value: any) => {
    if (this.props.annotationStorage) {
      this.props.annotationStorage.setValueByOther(key, value);
      if (this.containerRef.current) {
        const el = document.getElementById(key);
        if (el && this.containerRef.current.contains(el)) {
          let newValue = value;
          // Hack: for some reason pdfjs stores boolean in annotationStorage, but On & Off in messages
          if (el.getAttribute("type") === "checkbox") {
            newValue = { ...value, value: value.value ? "On" : "Off" };
          }
          el.dispatchEvent(
            new CustomEvent("updatefromsandbox", { detail: newValue })
          );
        }
      }
    }
  };

  async componentDidMount() {
    socket.on("annotation", this.updateAnnotation);

    document.addEventListener("selectionchange", this.onSelectionChange);

    const pages = [];
    for (let i = 1; i <= this.props.pdf.numPages; i++) {
      pages.push(this.props.pdf.getPage(i));
    }

    this.setState({ pages });
  }

  componentWillUnmount() {
    document.removeEventListener("selectionchange", this.onSelectionChange);
  }

  render() {
    return (
      <ViewerContext.Provider value={this.treeContext}>
        <div ref={this.containerRef} className="pdfDocument">
          {this.props.scale &&
            this.state.pages &&
            this.state.pages.map((page, index) => (
              <Page
                key={index}
                pageIndex={index}
                page={page}
                scale={this.props.scale!}
              />
            ))}
        </div>
      </ViewerContext.Provider>
    );
  }
}

const PdfDocument = connect((state: RootState) => ({
  annotationStorage: selectAnnotationStorage(state),
  scale: selectScale(state),
}))(_PdfDocument);

export default PdfDocument;
