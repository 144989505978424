import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import ViewPage from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { Router } from "@reach/router";
import HomePage from "./HomePage";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <HomePage path="/" />
        <ViewPage path="/f/:id" />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
