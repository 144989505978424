import { PDFDocumentProxy } from "pdfjs-dist/types/display/api";
import React, { useCallback } from "react";
import { useAppSelector } from "../app/hooks";
import { selectAnnotationStorage, User } from "./slice";
import style from "./topbar.module.css";
import { saveAs } from "file-saver";
import { DocumentDownloadIcon } from "@heroicons/react/outline";

function initial(name: string): string {
  const parts = name.split(/\s+/);
  if (parts.length > 1) {
    return (
      parts[0].charAt(0).toLocaleUpperCase() +
      parts[parts.length - 1].charAt(0).toLocaleUpperCase()
    );
  }
  return parts[0].charAt(0).toLocaleUpperCase();
}

const TopBar: React.FC<{
  users: User[];
  pdf?: PDFDocumentProxy;
  filename: string;
}> = ({ filename, users, pdf }) => {
  const annotationStorage = useAppSelector(selectAnnotationStorage);

  const save = useCallback(async () => {
    if (pdf && annotationStorage) {
      // wrong type definition in pdfjs
      const data = await pdf.saveDocument(annotationStorage as any);
      saveAs(new Blob([data]), filename);
    }
  }, [pdf, annotationStorage, filename]);

  return (
    <div className="p-2">
      <div className="navbar bg-base-100 shadow-xl rounded-box">
        <div className="navbar-start text-slate-600 px-3">{filename}</div>
        <div className="navbar-end">
          <ul className={style.userList}>
            {users.map((user, index) => (
              <li
                key={user.id}
                className={style.userIcon}
                style={{ zIndex: 999 - index, backgroundColor: user.color }}
              >
                {initial(user.name)}
              </li>
            ))}
          </ul>

          <button className="btn" onClick={save}>
            <DocumentDownloadIcon className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
