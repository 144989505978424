
export function djb2(str: string) {
  let hash = 5381;
  for (let i = 0; i < str.length; i++) {
    let c = str.charCodeAt(i);
    hash = ((hash << 5) + hash + c) & 0xffffffff; /* hash * 33 + c */
  }

  return hash;
}
