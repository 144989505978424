import { RouteComponentProps } from "@reach/router";
import React from "react";

import ViewerApp from "./pdf_viewer/viewer_app";

interface Props extends RouteComponentProps {
  id?: string;
}

interface State {
  data?: {
    filename: string;
    url: string;
  };
}

export default class ViewPage extends React.PureComponent<Props, State> {
  state: State = {};

  async componentDidMount() {
    const result = await fetch(`${process.env.REACT_APP_SERVER_URL}/info/` + this.props.id);
    const data = await result.json();
    this.setState({ data });
  }

  render() {
    const docId = this.props.id || '';
    if (this.state.data) {
      return (
        <ViewerApp
          id={docId}
          url={this.state.data.url}
          filename={this.state.data.filename}
        />
      );
    }
    return null;
  }
}
