import React, { createRef, useCallback, useState } from "react";
import { BASE_WEB_URL } from "../url";
import { CloudUploadIcon } from "@heroicons/react/outline";

const UploadWidget: React.FC<{ className?: string }> = ({ className }) => {
  const fileRef = createRef<HTMLInputElement>();
  const [progress, setProgress] = useState(NaN);
  const [dragOver, setDragOver] = useState(false);

  const processFile = useCallback((file: File) => {
    const formData = new FormData();
    formData.append("fn", file);

    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      const json = JSON.parse(xhr.response);

      (
        window.top || window
      ).location.href = `${BASE_WEB_URL}/f/${json.link_hash}#created`;
    };

    xhr.upload.onprogress = (e) => {
      setProgress(e.loaded / e.total);
    };

    xhr.open("POST", `${process.env.REACT_APP_SERVER_URL}/upload`);
    xhr.send(formData);
  }, []);

  const onUpload = useCallback(async () => {
    if (
      fileRef.current &&
      fileRef.current.files &&
      fileRef.current.files.length
    ) {
      const file = fileRef.current.files[0];
      processFile(file);
    }
  }, [fileRef, processFile]);

  const onDragEnter: React.DragEventHandler = useCallback((e) => {
    e.preventDefault();
    setDragOver(true);
  }, []);

  const onDragLeave: React.DragEventHandler = useCallback((e) => {
    e.preventDefault();
    setDragOver(false);
  }, []);

  const onDrop: React.DragEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      setDragOver(false);
      if (e?.dataTransfer?.files?.length) {
        processFile(e.dataTransfer.files[0]);
      }
    },
    [processFile]
  );

  const uploading = !isNaN(progress);
  return (
    <label
      onDragEnter={onDragEnter}
      onDragOver={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      className={
        (className || "") +
        " flex flex-col items-center justify-center shadow-inner border border-slate-200 w-80 h-80 text-center relative block "
      }
    >
      {uploading && (
        <div
          className="absolute bg-success bottom-0 left-0 right-0"
          style={{ height: `${progress * 100}%` }}
        ></div>
      )}
      <CloudUploadIcon
        className={
          "text-slate-400 w-40 h-40 " +
          (dragOver ? "drop-shadow-[0_0_15px_rgba(0,125,0,0.75)]" : "")
        }
      />
      {!uploading && (
        <>
          <div>Choose your PDF file to upload</div>
          <div>Or, drag a PDF file here</div>
        </>
      )}
      {uploading && <div>Uploading: {progress * 100}%</div>}
      <input
        type="file"
        accept=".pdf,application/pdf"
        ref={fileRef}
        className="opacity-0"
        onChange={onUpload}
      />
    </label>
  );
};

export default UploadWidget;
