import io, { Socket } from "socket.io-client";
import { ClientEvents, SelectionBox, ServerEvents } from "./pdf_viewer/types";

const WS_SERVER =
  process.env.NODE_ENV === "production"
    ? "https://staging-server.pdfwith.us"
    : "http://localhost:4000";

export const socket: Socket<ServerEvents, ClientEvents> = io(WS_SERVER, {
  autoConnect: false,
});

export function publishSelection(boxes: SelectionBox[]) {
  // when selecting, somehow socket will fail to emit and therefore drop the message
  socket.emit("select", boxes);
}

export function clearSelection() {
  socket.emit("select", []);
}

export function publishMousePosition(
  page: number,
  pos: { x: number; y: number }
) {
  socket.volatile.emit("mouse", page, pos.x, pos.y);
}

export function clearMousePosition() {
  socket.volatile.emit("clearmouse");
}

export function publishAnnotation(key: string, value: any) {
  socket.emit("annotation", key, value);
}
