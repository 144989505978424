import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { uidToColor } from "../utils/color";
import { AnnotationStorage } from "./annotation_storage";

export interface User {
  id: string;
  name: string;
  color: string;
}

interface Connection {
  connectionId: string;
  userId: string;
}

interface Auth {
  name: string;
}

export interface PdfViewerState {
  users: User[];
  connections: Connection[];
  auth?: Auth;
  annotationStorage?: AnnotationStorage;
  scale?: number;
}

const initialState: PdfViewerState = {
  users: [],
  connections: [],
  scale: 1,
};

export const pdfViewerSlice = createSlice({
  name: "pdfViewer",
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<{ name: string }>) => {
      state.auth = {
        name: action.payload.name,
      };
    },
    onNewClient: (
      state,
      action: PayloadAction<{ connectionId: string; name: string }>
    ) => {
      state.connections.push({
        connectionId: action.payload.connectionId,
        userId: action.payload.connectionId,
      });

      state.users.push({
        id: action.payload.connectionId,
        name: action.payload.name,
        color: uidToColor(action.payload.connectionId),
      });
    },

    onClientLeave: (state, action: PayloadAction<{ connectionId: string }>) => {
      const connId = action.payload.connectionId;
      const connIndex = state.connections.findIndex(
        (c) => c.connectionId === connId
      );
      if (connIndex > -1) {
        const userIndex = state.users.findIndex(
          (u) => u.id === state.connections[connIndex].userId
        );
        if (userIndex > -1) {
          state.users.splice(userIndex, 1);
        }
        state.connections.splice(connIndex, 1);
      }
    },

    onInitialAnnotations: (
      state,
      action: PayloadAction<{ annotations: any }>
    ) => {
      state.annotationStorage = new AnnotationStorage(
        action.payload.annotations
      );
    },

    zoomIn: (state) => {
      if (state.scale) {
        state.scale += 0.1;
      }
    },
    zoomOut: (state) => {
      if (state.scale) {
        state.scale -= 0.1;
      }
    },
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    // // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
});

export const {
  setName,
  onNewClient,
  onClientLeave,
  onInitialAnnotations,
  zoomIn,
  zoomOut,
} = pdfViewerSlice.actions;

export const selectUsers = (state: RootState) => state.pdfViewer.users;

export const selectConnections = (state: RootState) =>
  state.pdfViewer.connections;

export const selectAuth = (state: RootState) => state.pdfViewer.auth;

export const selectScale = (state: RootState) => state.pdfViewer.scale;

export const selectUserByConnection = createSelector(
  selectUsers,
  selectConnections,
  (users, connections) => {
    const usersMap = new Map<string, User>();
    for (const u of users) {
      usersMap.set(u.id, u);
    }
    const userByConn = new Map<string, User>();
    for (const c of connections) {
      const u = usersMap.get(c.userId);
      if (u) {
        userByConn.set(c.connectionId, u);
      }
    }
    return userByConn;
  }
);

export const selectAnnotationStorage = (state: RootState) =>
  state.pdfViewer.annotationStorage;

export default pdfViewerSlice.reducer;
