import React, { useState, useEffect, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import PdfDocument from "./pdf_document";
import * as pdfjs from "pdfjs-dist";
import {
  onClientLeave,
  onInitialAnnotations,
  onNewClient,
  selectAuth,
  selectUsers,
  setName,
  zoomIn,
  zoomOut,
} from "./slice";
import TopBar from "./topbar";
import ReactModal from "react-modal";
import { clearMousePosition, socket } from "../sync_manager";
import { PDFDocumentProxy } from "pdfjs-dist/types/display/api";

import "pdfjs-dist/web/pdf_viewer.css";
import { BASE_WEB_URL } from "../url";

import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/outline";

enum ModalState {
  UNKNOWN = 0,
  GUEST = 1,
  CREATOR = 2,
}
const Modal: React.FC<{ docid: string; filename: string }> = ({
  docid,
  filename,
}) => {
  const [modalState, setModalState] = useState<ModalState>(ModalState.UNKNOWN);
  useEffect(() => {
    if (window.location.hash === "#created") {
      window.location.hash = "";
      setModalState(ModalState.CREATOR);
    } else {
      setModalState(ModalState.GUEST);
    }
  }, [setModalState]);
  return (
    <div className="modal modal-open">
      <div className="modal-box">
        {modalState === ModalState.CREATOR && <CreatorModal docid={docid} />}
        {modalState === ModalState.GUEST && <GuestModal filename={filename} />}
      </div>
    </div>
  );
};

const CopyButton: React.FC<{ value: string, className?: string }> = React.memo(({ value, className }) => {
  const [copied, setCopied] = useState(false);
  const onCopy = useCallback(() => {
    navigator.clipboard.writeText(value).then(() => setCopied(true));
  }, [value]);
  return <button className={className} onClick={onCopy}>{copied ? "Copied" : "Copy"}</button>;
});

const CreatorModal: React.FC<{ docid: string }> = ({ docid }) => {
  const dispatch = useAppDispatch();
  const [nameInput, setNameInput] = useState("");
  const link = `${BASE_WEB_URL}/f/${docid}`;
  return (
    <>
      <h1 className="text-xl font-bold">Your document is now available!</h1>
      <p className="mt-4">Use the following link to share</p>
      <div className="flex mt-1">
        <input className="input input-bordered input-primary rounded-r-none flex-auto" value={link} readOnly />
        <CopyButton className="btn btn-primary rounded-l-none flex-none" value={link} />
      </div>
      <hr className="my-4" />
      <div className="font-medium">To start collaborating, enter the information below</div>
      <div className="form-control">
        <label className="label text-gray-500" htmlFor="creator-modal-name">
          Name
        </label>
        <input
          className="input input-bordered"
          id="creator-modal-name"
          value={nameInput}
          onChange={(e) => setNameInput(e.currentTarget.value)}
        />
        <p className="text-sm text-gray-500 p-1">
          Your name will be shared with your collaborators.
        </p>
        <div className="flex align-items-center">
          <button
            className="btn btn-primary"
            disabled={!nameInput}
            onClick={() => dispatch(setName({ name: nameInput }))}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

const GuestModal: React.FC<{ filename: string }> = ({ filename }) => {
  const dispatch = useAppDispatch();
  const [nameInput, setNameInput] = useState("");
  return (
    <>
      <h1 className="text-xl font-bold">
        You are invited to collaborate on {filename}
      </h1>
      <p>Please provide the information below</p>
      <div className="form-control">
        <label className="label text-gray-500" htmlFor="guest-modal-name">
          Name
        </label>
        <input
          className="input input-bordered"
          id="guest-modal-name"
          value={nameInput}
          onChange={(e) => setNameInput(e.currentTarget.value)}
        />
        <p className="text-sm text-gray-500 p-1">
          Your name will be shared with your collaborators.
        </p>
        <p>
          <button
            className="btn btn-primary"
            disabled={!nameInput}
            onClick={() => dispatch(setName({ name: nameInput }))}
          >
            Submit
          </button>
        </p>
      </div>
    </>
  );
};

const ConnectionManager: React.FC<{ docid: string }> = React.memo(
  ({ docid }) => {
    const auth = useAppSelector(selectAuth);
    const dispatch = useAppDispatch();

    useEffect(() => {
      if (auth) {
        socket.auth = { ...auth, docid };
        socket.connect();
        socket.emit("startsession");

        socket.on("newclient", (clients) => {
          for (const client of clients) {
            dispatch(
              onNewClient({
                connectionId: client.origin,
                name: client.name,
              })
            );
          }
        });

        socket.on("initialannotations", (annotations) => {
          dispatch(onInitialAnnotations({ annotations }));
        });

        socket.on("clientleave", (clients) => {
          for (const client of clients) {
            dispatch(
              onClientLeave({
                connectionId: client.origin,
              })
            );
          }
        });
      }

      const visibilityChange = function () {
        if (document.visibilityState === "hidden") {
          clearMousePosition();
        }
      };

      document.addEventListener("visibilitychange", visibilityChange);

      return () => {
        socket.disconnect();
        document.removeEventListener("visibilitychange", visibilityChange);
      };
    }, [auth, dispatch, docid]);

    return null;
  }
);

const Toolbar: React.FC<{}> = (props) => {
  const dispatch = useAppDispatch();
  return (
    <div className="fixed z-10 left-10 bottom-10 right-10 flex flex-row justify-center">
      <div className="bg-base-300 p-2">
        <button onClick={() => dispatch(zoomOut())}>
          <MinusCircleIcon className="w-4 h-4" />
        </button>
        <button onClick={() => dispatch(zoomIn())}>
          <PlusCircleIcon className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

const ViewerApp: React.FC<{
  id: string;
  filename: string;
  url: string;
}> = ({ id, url, filename }) => {
  const users = useAppSelector(selectUsers);
  const auth = useAppSelector(selectAuth);

  const [pdf, setPdf] = useState<PDFDocumentProxy | undefined>();
  const [progress, setProgress] = useState<number | undefined>();

  useEffect(() => {
    const task = pdfjs.getDocument(url);

    task.promise.then((val) => {
      setPdf(val);
    });

    task.onProgress = (status: { loaded: number; total: number }) => {
      setProgress(status.loaded / status.total);
    };

    return () => {
      task.destroy();

      socket.disconnect();
    };
  }, [setPdf, url]);

  return (
    <div>
      <ConnectionManager docid={id} />
      {auth ? null : <Modal filename={filename} docid={id} />}
      <TopBar users={users} filename={filename} pdf={pdf} />

      {pdf ? (
        <div>
          <PdfDocument pdf={pdf} />
          <Toolbar />
        </div>
      ) : (
        <div>{progress}</div>
      )}
    </div>
  );
};

export default ViewerApp;
