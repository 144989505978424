import { publishAnnotation } from "../sync_manager";

function objectFromMap<V>(m: Map<string | number, V>): { [k: string]: V } {
  return Object.fromEntries(m);
}

export class AnnotationStorage {
  _storage: Map<string, any>;
  _modified: boolean;

  onSetModified: (() => void) | null;
  onResetModified: (() => void) | null;

  constructor(initialValue?: { [k: string]: any }) {
    this._storage = new Map();
    if (initialValue) {
      for (const [k, v] of Object.entries(initialValue)) {
        this._storage.set(k, v);
      }
    }
    this._modified = false;

    // Callbacks to signal when the modification state is set or reset.
    // This is used by the viewer to only bind on `beforeunload` if forms
    // are actually edited to prevent doing so unconditionally since that
    // can have undesirable effects.
    this.onSetModified = null;
    this.onResetModified = null;
  }

  /**
   * Get the value for a given key if it exists, or return the default value.
   *
   * @public
   * @memberof AnnotationStorage
   * @param {string} key
   * @param {Object} defaultValue
   * @returns {Object}
   */
  getValue(key: string, defaultValue: any) {
    const obj = this._storage.get(key);
    return obj !== undefined ? obj : defaultValue;
  }

  setValueByOther(key: string, value: any) {
    const obj = this._storage.get(key);
    let modified = false;
    if (obj !== undefined) {
      for (const [entry, val] of Object.entries(value)) {
        if (obj[entry] !== val) {
          modified = true;
          obj[entry] = val;
        }
      }
    } else {
      this._storage.set(key, value);
      modified = true;
    }
    if (modified) {
      this._setModified();
    }
    return modified;
  }

  setValue(key: string, value: any) {
    if (this.setValueByOther(key, value)) {
      publishAnnotation(key, value);
    }
  }

  getAll() {
    return this._storage.size > 0 ? objectFromMap(this._storage) : null;
  }

  get size() {
    return this._storage.size;
  }

  private _setModified() {
    if (!this._modified) {
      this._modified = true;
      if (typeof this.onSetModified === "function") {
        this.onSetModified();
      }
    }
  }

  resetModified() {
    if (this._modified) {
      this._modified = false;
      if (typeof this.onResetModified === "function") {
        this.onResetModified();
      }
    }
  }

  /**
   * PLEASE NOTE: Only intended for usage within the API itself.
   * @ignore
   */
  get serializable() {
    return this._storage.size > 0 ? this._storage : null;
  }
}
