import React, { createRef, useEffect, useState } from "react";
import {
  TextLayerBuilder,
  AnnotationLayerBuilder,
  SimpleLinkService,
} from "pdfjs-dist/web/pdf_viewer";
import ViewerContext from "./context";
import type { PDFPageProxy } from "pdfjs-dist/types/display/api";
import {
  clearMousePosition,
  publishMousePosition,
  socket,
} from "../sync_manager";
import "./page.css";
import { NullL10n } from "./pdfjs_l10n";
import { scaleFactor } from "./utils";
import { SelectionBox } from "./types";
import { uidToColor } from "../utils/color";
import { selectAnnotationStorage, selectUserByConnection } from "./slice";
import { useAppSelector } from "../app/hooks";
import type { PageViewport } from "pdfjs-dist/types/display/display_utils";

const CursorIcon: React.FC<{ color: string }> = React.memo(({ color }) => (
  <svg width="13" height="19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <path d="M2 16V2l8 11-8 3z" fill={color} />
      <path d="M2 16V2l8 11-8 3z" stroke="#eee" />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x=".5"
        y="-.538"
        width="13.291"
        height="20.259"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx="1" dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
));

const Cursor: React.FC<{
  name: string;
  color: string;
  x: number;
  y: number;
}> = ({ name, color, x, y }) => {
  return (
    <div
      style={{
        position: "absolute",
        left: x,
        top: y,
      }}
    >
      <CursorIcon color={color} />
      <div
        style={{
          backgroundColor: color,
          marginLeft: 8,
        }}
      >
        {name}
      </div>
    </div>
  );
};

interface BoxState {
  [id: string]: SelectionBox[];
}

interface MouseState {
  [id: string]: { x: number; y: number } | undefined;
}

const AnnotationLayerManager: React.FC<{
  containerRef: React.RefObject<HTMLDivElement>;
  page: PDFPageProxy;
  viewport: PageViewport;
}> = ({ containerRef, page, viewport }) => {
  
  const annotationStorage = useAppSelector(selectAnnotationStorage);
  useEffect(() => {
    if (annotationStorage) {
      const mouseState = Object.create(null);

      const mouseDown = () => {
        mouseState.isDown = true;
      };
      window.addEventListener("mousedown", mouseDown);
      const mouseUp = () => {
        mouseState.isDown = false;
      };
      window.addEventListener("mouseup", mouseUp);

      const existing =
        containerRef.current?.getElementsByClassName("annotationLayer");

      if (existing) {
        for (let i = 0; i < existing.length; i++) {
          containerRef.current?.removeChild(existing[i]);
        }
      }

      const annotationLayerBuilder = new AnnotationLayerBuilder({
        pageDiv: containerRef.current,
        pdfPage: page,
        imageResourcesPath: "/",
        renderInteractiveForms: true,
        linkService: new SimpleLinkService(),
        l10n: NullL10n,
        annotationStorage: annotationStorage,
        enableScripting: true,
        hasJSActionsPromise: Promise.resolve(true),
        mouseState: mouseState,
      });

      annotationLayerBuilder.render(viewport, "display");

      return () => {
        window.removeEventListener("mousedown", mouseDown);
        window.removeEventListener("mouseup", mouseUp);
      };
    }
  }, [containerRef, annotationStorage, page, viewport]);
  return null;
};

const CursorLayer: React.FC<{ pageIndex: number, scale: number }> = ({ pageIndex, scale }) => {
  const [pointers, setPointers] = useState<MouseState>({});
const trueScale = scale || 1;
  useEffect(() => {
    const updateMouse = (
      origin: string,
      page: number,
      x: number,
      y: number
    ) => {
      setPointers((pointers) =>
        Object.assign({}, pointers, {
          [origin]: page === pageIndex ? { x, y } : undefined,
        })
      );
    };

    const removeCursor = (connections: string[]) => {
      setPointers((pointers) => {
        const newPointers = Object.assign({}, pointers);
        for (const c of connections) {
          delete newPointers[c];
        }
        return newPointers;
      });
    };

    const clientLeave = (clients: { origin: string }[]) => {
      removeCursor(clients.map((c) => c.origin));
    };

    const clearMouse = (origin: string) => {
      removeCursor([origin]);
    };

    socket.on("mouse", updateMouse);
    socket.on("clientleave", clientLeave);
    socket.on("clearmouse", clearMouse);

    return () => {
      socket.off("mouse", updateMouse);
      socket.off("clientleave", clientLeave);
      socket.off("clearmouse", clearMouse);
    };
  }, [pageIndex]);

  const usersByConn = useAppSelector(selectUserByConnection);

  return (
    <div className="cursorLayer">
      {Object.entries(pointers || {}).map(([uid, pointer]) => {
        if (pointer) {
          const user = usersByConn.get(uid);

          return (
            <Cursor
              key={uid}
              color={user?.color || "#EEE"}
              x={pointer.x * trueScale}
              y={pointer.y * trueScale}
              name={user?.name || "Anonymous"}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

interface State {
  boxes?: BoxState;
  pointers?: MouseState;
  viewport?: PageViewport;
  page?: PDFPageProxy;
}

interface Props {
  pageIndex: number;
  page: Promise<PDFPageProxy>;
  scale: number;
}

class Page extends React.PureComponent<Props, State> {
  private containerRef = createRef<HTMLDivElement>();
  private canvasRef = createRef<HTMLCanvasElement>();
  private textRef = createRef<HTMLDivElement>();
  context: React.ContextType<typeof ViewerContext>;

  state: State = {};

  private updateBoxes = (origin: string, boxes: SelectionBox[]) => {
    this.setState({
      boxes: Object.assign({}, this.state?.boxes, {
        [origin]: boxes.filter((box) => box.page === this.props.pageIndex),
      }),
    });
  };

  private mouseMove: React.MouseEventHandler<HTMLDivElement> = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const scale = this.props.scale || 1;
    publishMousePosition(this.props.pageIndex, {
      x: (e.clientX - rect.x) / scale,
      y: (e.clientY - rect.y) / scale,
    });
  };

  private mouseLeave: React.MouseEventHandler<HTMLDivElement> = () => {
    clearMousePosition();
  };

  private draw = async () => {
    const context = this.context;

    if (context) {
      const page = await this.props.page;

      const outputScale = scaleFactor();

      const viewport = page.getViewport({ scale: this.props.scale });

      this.setState({
        viewport,
        page,
      });

      if (this.canvasRef.current) {
        this.canvasRef.current.width = viewport.width * outputScale;
        this.canvasRef.current.height = viewport.height * outputScale;
        const canvasCtx = this.canvasRef.current.getContext("2d");
        if (canvasCtx) {
          // Rendering area
          const transform =
            outputScale === 1
              ? undefined
              : [outputScale, 0, 0, outputScale, 0, 0];

          await page.render({
            canvasContext: canvasCtx,
            transform,
            viewport,
          }).promise;

          if (this.textRef.current) {
            const node = this.textRef.current;
            while (node.lastChild) {
              node.removeChild(node.lastChild);
            }
          }

          const textLayerBuilder = new TextLayerBuilder({
            textLayerDiv: this.textRef.current,
            pageIndex: this.props.pageIndex,
            viewport,
            eventBus: context.eventBus,
            enhanceTextSelection: false,
          });

          const readableStream = page.streamTextContent({
            normalizeWhitespace: true,
            disableCombineTextItems: false,
            // includeMarkedContent: true,
          });
          textLayerBuilder.setTextContentStream(readableStream);
          textLayerBuilder.render();
        }
      }
    }
  };

  componentDidMount() {
    socket.on("select", this.updateBoxes);
    this.draw();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.scale !== this.props.scale) {
      this.draw();
    }
  }

  render() {
    const scale = this.props.scale || 1;
    return (
      <div
        className="page"
        style={{
          position: "relative",
          width: this.state.viewport?.width,
          height: this.state.viewport?.height,
        }}
        onMouseMove={this.mouseMove}
        onMouseLeave={this.mouseLeave}
        ref={this.containerRef}
      >
        {this.state?.page && this.state?.viewport && (
          <AnnotationLayerManager
            containerRef={this.containerRef}
            page={this.state.page}
            viewport={this.state.viewport}
          />
        )}
        <canvas
          ref={this.canvasRef}
          style={{
            width: this.state.viewport?.width,
            height: this.state.viewport?.height,
          }}
        />
        <CursorLayer pageIndex={this.props.pageIndex} 
              scale={scale} />
        <div className="othersSelectionLayer">
          {Object.entries(this.state?.boxes || {}).map(([uid, boxes]) => (
            <>
              {boxes.map((box, index) => (
                <div
                  style={{
                    position: "absolute",
                    left: box.x * scale,
                    bottom: box.y * scale,
                    width: box.width * scale,
                    height: box.height * scale,
                    backgroundColor: uidToColor(uid),
                  }}
                  key={`${uid}-${index}`}
                />
              ))}
            </>
          ))}
        </div>
        <div className="textLayer" ref={this.textRef} />
      </div>
    );
  }
}

Page.contextType = ViewerContext;

export default Page;
